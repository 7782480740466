@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --toastify-color-info: #0072fb;
  --toastify-color-success: #0ad6a1;
  --toastify-color-warning: #ffe066;
  --toastify-color-error: #ff4658;
}

body {
  font-family: 'Arboria', sans-serif;
  font-size: 16px;
  background-color: #fbfbfb;
  color: black;
  scrollbar-gutter: stable;
}

img {
  max-width: 100%;
}

a {
  text-decoration: none;
}

button {
  font-family: 'Arboria', sans-serif;
  font-size: 16px;
  background: none;
  outline: none;
  cursor: pointer;
  padding: 0;
}

/* scrollbar */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(255, 255, 255, 0.3);
}

input,
textarea {
  font-family: 'Arboria', sans-serif;
  font-size: 16px;
  outline: none;
  border: none;
  background-color: transparent;
}

.bg-blocked {
  background: repeating-linear-gradient(30deg, #e9e9e9, #cecece 3px, #cecece 3px, #e9e9e9 6px);
}

.shadow-agora {
  box-shadow:
    rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
    rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}

.fc-timegrid {
  max-height: calc(100vh - 184px);
  overflow-y: scroll;
}

.fc-timegrid table thead {
  width: 100%;
  position: sticky;
  background: white;
  top: 0;
  z-index: 20;
}

.fc-timegrid::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

.fc-timegrid::-webkit-scrollbar-thumb {
  background: #dcdcdc;
  border-radius: 4px;
}

.fc-timegrid::-webkit-scrollbar-track {
  background: #e9e9e9;
  border-radius: 4px;
}

.skeleton {
  --text-opacity: 0;
  background-image: linear-gradient(100deg, #edf2f7 0%, #f4f7fa 20%, #edf2f7 40%);
  background-position: 50%;
  background-size: 200%;
  animation: skeleton 1.25s infinite linear;
}

@keyframes skeleton {
  0% {
    background-position: 50%;
  }
  50%,
  100% {
    background-position: -100%;
  }
}

.link {
  cursor: pointer;
  color: #0072fb;
  font-weight: 500;
  transition: color 0.2s ease-in-out;
}
.link:hover {
  color: #0052d4;
  text-decoration: underline;
}

.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.no-scrollbar::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* Chrome, Safari and Opera */
}

.react-tel-input {
  height: 46px;
  width: 100%;
  radius: 4px;
}

.react-tel-input .form-control {
  border: 1px solid rgba(0, 0, 0, 0.1) !important;
}

.react-tel-input input {
  width: 100% !important;
  height: 46px !important;
}

.wrapper {
  max-width: 1200px;
  margin: 0 auto;
  width: 90%;
}

@keyframes ping {
  0% {
    box-shadow: 0px 0px 5px 0px rgba(0, 114, 255, 0.3);
  }
  100% {
    box-shadow: 0px 0px 15px 10px rgba(0, 114, 255, 0);
  }
}

.button-animation {
  position: relative;
  width: 40px;
  height: 40px;
  border: none;
  background-color: transparent;
  padding: 0;
}

.ping-animation {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  box-shadow: 0px 0px 5px 0px rgba(0, 114, 255, 0.3);
  animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite;
}

.gradient-button {
  background: linear-gradient(90deg, #0072ff 0%, rgba(12, 117, 247, 0.5) 100%);
  transition: background 0.5s ease-in-out;
}

.gradient-button:hover {
  background: linear-gradient(90deg, #0084ff 0%, rgba(12, 127, 247, 0.6) 100%);
}

.gradient-button:active {
  background: linear-gradient(90deg, #0060cc 0%, rgba(10, 97, 220, 0.6) 100%);
}

.card-container {
  position: relative;
  transition: box-shadow 0.3s ease;
}

.card-container:hover {
  box-shadow:
    11px 11px 28px 0px #c4cad3,
    -11px -11px 21px 0px rgba(255, 255, 255, 0.8);
  box-shadow:
    13.67891px 13.67891px 34.19726px 0px #c4cad3,
    -13.67891px -13.67891px 25.64795px 0px rgba(255, 255, 255, 0.8);
}

.card-image-container {
  position: relative;
  width: 190px;
  height: 220px;
}

.card-image-container img {
  width: 100%;
  height: 100%;
}

.card-image-container:hover::before {
  z-index: 2;
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0.7) 100%); /* darker gradient */
  pointer-events: none; /* allow clicks to pass through */
}

.card-image-container:hover .store-link {
  display: block;
  z-index: 3;
}

.image-enter {
  transform: translate(-50%, -50%);
  opacity: 0;
  transition:
    transform 1s,
    opacity 1s;
}

.image-enter-done {
  transform: translate(0%, 0%);
  opacity: 1;
}

.fade-enter {
  opacity: 0.01;
  transform: translateY(-20px);
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition:
    opacity 500ms ease-in-out,
    transform 500ms ease-in-out;
}

.fade-exit {
  opacity: 1;
  transform: translateY(0);
}

.fade-exit.fade-exit-active {
  opacity: 0.01;
  transform: translateY(20px);
  transition:
    opacity 500ms ease-in-out,
    transform 500ms ease-in-out;
}

.modal-shadow {
  box-shadow:
    0 1px rgb(19, 21, 23, 0.08),
    0 3.3px 2.7px rgba(0, 0, 0, 0.03),
    0 8.3px 6.9px rgba(0, 0, 0, 0.04),
    0 17px 14.2px rgba(0, 0, 0, 0.05),
    0 35px 29.2px rgba(0, 0, 0, 0.06),
    0 96px 80px rgba(0, 0, 0, 0.07),
    0 -4px 4px 0 rgba(0, 0, 0, 0.04) inset;
}

.video-overlay {
  background: rgba(0, 0, 0, 0.5);
}

.video-play-button {
  position: absolute;
  z-index: 20;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.video-play-button:before {
  content: '';
  position: absolute;
  z-index: 10;
  left: 45%;
  top: 45%;
  transform: translate(-45%, -45%);
  width: 60px;
  height: 60px;
  background: rgba(255, 255, 255, 0.6); /* Changed to white and increased opacity */
  border-radius: 50%;
  animation: pulse-border 1.5s ease-out infinite;
}

@keyframes pulse-border {
  0% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 0.8; /* Increased initial opacity */
  }
  100% {
    transform: translate(-50%, -50%) scale(1.5);
    opacity: 0;
  }
}

.bg-landing-cta {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%),
    linear-gradient(301deg, #00c2ff 52.87%, rgba(0, 114, 255, 0) 88.1%),
    linear-gradient(130deg, #ff2f2f 10.43%, #e9e9e9 92.78%),
    radial-gradient(70.71% 70.71% at 50% 50%, #ff4658 0%, rgba(255, 70, 88, 0.53) 54.17%, #f00 100%),
    linear-gradient(59deg, #2200f2 0%, rgba(0, 114, 255, 0.5) 100%),
    linear-gradient(154deg, #f00 0%, #e9e9e9 74.04%), linear-gradient(301deg, #f00 0%, #0038ff 84.63%),
    linear-gradient(136deg, #0072ff 11.12%, #f00 86.47%),
    radial-gradient(160.56% 99.04% at 50% 50%, #b50000 0%, #0034bb 100%);
  background-blend-mode: normal, multiply, overlay, color-burn, screen, difference, difference, difference,
    normal;
}

@keyframes marquee {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

.marquee-container {
  overflow-x: hidden;
  white-space: nowrap;
  position: relative;
  width: 100%;
}

.marquee-track {
  display: flex;
  width: calc(550% + 4rem); /* El doble del ancho total más el margen */
  animation: marquee 20s linear infinite; /* Ajusta para cambiar la velocidad */
}

.marquee-logo {
  width: 200px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes move {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-50%);
  }
}

.column-animation {
  animation: move 30s linear infinite;
}

.column-animation-offset {
  animation: move 80s linear infinite;
}

.column-animation,
.column-animation-offset {
  will-change: transform;
  backface-visibility: hidden;
  perspective: 1000px;
}

.debug-border * {
  border: 1px solid red !important;
}

.shadow-timeline-card {
  box-shadow:
    11.423px 11.423px 28.556px 0px #c4cad3,
    -11.423px -11.423px 21.417px 0px rgba(255, 255, 255, 0.8);
}

.gradient-overlay::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0.6) 60%, rgba(255, 255, 255, 100) 100%);
  z-index: 1;
  border-radius: inherit;
  --tw-backdrop-blur: blur(10px);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast)
    var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert)
    var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.select-content {
  width: var(--radix-select-trigger-width);
  max-height: var(--radix-select-content-available-height);
}
